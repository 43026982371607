import { defineStore } from 'pinia';
import api from '@/api/login.js'
import {router} from '../routes'
import { ElMessage,ElLoading } from 'element-plus'
import jwtDecode from "jwt-decode";

interface UserInfo {
  id: string;
  username: string;
  email: string;
  logintime: string,
}

export const useAuthStore = defineStore({
  id: 'auth',
  state: () => ({
    isLoggedin: false,
    userInfo: undefined as UserInfo | undefined,
    token: '',    
  }),
  actions: {
    login(logform: any) {
      const loading = ElLoading.service({
        lock: true,
        text: 'Loading',
        background: 'rgba(0, 0, 0, 0.7)',
      });
      api.loginApi(logform).then((res: any) => {
        //console.log('login', res)
        if (res) {
          loading.close();
          const tokenInfo = jwtDecode(res);          
          const {username} = tokenInfo as any;
          //console.log("username",username);
          const now = new Date();
          const currentime = now.toLocaleTimeString(); // 获取本地时间的字符串表示
          this.isLoggedin = true;
          this.userInfo = {
            id: '12345',
            username: username,
            email: '',
            logintime: currentime,
          };
          this.token = res;
          window.localStorage.setItem('token', this.token);
          router.push('/ListenMusic');          
        }
      }).catch(error => {
        loading.close();
        console.log(error);
        ElMessage.error(error.message);
      })
      
    },
    logout() {
      // 执行注销操作，并清除所有状态信息及 localStorage 中的 token
      console.log("注销");
      this.isLoggedin = false;
      this.userInfo = undefined;
      this.token = '';
      window.localStorage.removeItem('token');
      router.push('/login')
    },
  },
  persist: {
    enabled: true,
  }
});