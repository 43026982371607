<template>
     <!-- 页面上下结构 -->
     <el-container class="topContainer">
          <el-header>
               <div id="aplayer" class="aplayer"></div>
               <!-- <div class="toolbar">                    
                    <el-dropdown>
                         <el-icon style="margin-right: 8px; margin-top: 1px">
                              <setting />
                         </el-icon>
                         <template #dropdown>
                              <el-dropdown-menu>
                                   <el-dropdown-item>View</el-dropdown-item>
                                   <el-dropdown-item>Add</el-dropdown-item>
                                   <el-dropdown-item>Delete</el-dropdown-item>
                              </el-dropdown-menu>
                         </template>
                    </el-dropdown>
                    <span>Tom</span>
               </div> -->
          </el-header>
          <el-container class="layout-container-demo" style="height:inherit;">
               <el-aside v-if="IsPC">
                    <el-scrollbar>
                         <el-form ref="formRef" :model="sizeForm" label-width="50px" label-position="top" size="default">
                              <el-form-item label="歌名" prop="songName" size="small">
                                   <el-input v-model="sizeForm.songName" @keyup.enter="onSubmit"/>
                              </el-form-item>
                              <el-form-item label="歌手" prop="singerName" size="small">
                                   <el-input v-model="sizeForm.singerName" @keyup.enter="onSubmit"/>
                              </el-form-item>
                              <el-form-item label="专辑" prop="albumName" size="small">
                                   <el-input v-model="sizeForm.albumName" disabled />
                              </el-form-item>
                              <el-form-item id="btn_ListenMusic_querySong">
                                   <el-button type="info" @click="resetForm" size="small">重置</el-button>
                                   <el-button type="success" @click="onSubmit" size="small">查询</el-button>
                              </el-form-item>
                         </el-form>
                    </el-scrollbar>
               </el-aside>
               <el-button circle class="querybutton" v-if="!IsPC" @click="drawer_querysong = true">
                    <el-icon color="rgb(186, 186, 248)">
                         <Aim />
                    </el-icon>
               </el-button>
               <!-- <el-button class="querybutton" v-if="IsPC" icon="Aim" @click="drawer_querysong = true" circle /> -->
               <!-- 移动端-抽屉弹出查询 -->
               <el-drawer id="singquery_dom_id" v-model="drawer_querysong" direction="btt" :with-header="true" >
                    <template #header>
                         <h4>搜索歌曲</h4>
                    </template>
                    <template #default>
                         <el-form :inline="false" ref="formRef" :model="sizeForm" label-width="50px" label-position="left" size="small">
                              <el-form-item label="歌名" prop="songName">
                                   <el-input v-model="sizeForm.songName" />
                              </el-form-item>
                              <el-form-item label="歌手" prop="singerName">
                                   <el-input v-model="sizeForm.singerName" />
                              </el-form-item>
                         </el-form>
                    </template>
                    <template #footer>
                         <div style="flex: auto">
                              <el-button @click="resetForm">重置</el-button>
                              <el-button type="primary" @click="onSubmit">查询</el-button>
                         </div>
                    </template>
               </el-drawer>
               <el-main>
                    <el-scrollbar>
                         <!-- music_loading,music_noMore,music_disabled, -->
                         <el-space :static="true" wrap v-infinite-scroll="loadMusicList_pub"
                              :infinite-scroll-disabled="music_disabled" infinite-scroll-distance="25"
                              style="justify-content:center">
                              <el-card :static="true" v-for="music in musicList.list" :key="music" class="box-card"
                                   :style="{ width: card_musicWidthVal + 'px', textAlign: 'center' }">
                                   <el-avatar style="width: auto; height: 100px" :src="music.cover" />
                                   <div truncated>
                                        <el-text truncated :title='music.name + " - " + music.artist'>
                                             <span style="font-size: 13px;">{{ music.name }}</span>
                                             <span style="font-size: 11px;color: silver;"> - {{ music.artist }}</span>
                                        </el-text>
                                   </div>
                                   <div class="cardbottom">
                                        <el-button text class="button" @click="playThisMusic(music)">
                                             <el-icon size="1.5em" color="rgb(82, 243, 181)">
                                                  <CaretRight />
                                             </el-icon></el-button>
                                        <el-button text class="button" @click="AddtoList(music)">
                                             <el-icon size="1.3em" color="rgb(186, 186, 248)">
                                                  <Plus />
                                             </el-icon></el-button>
                                   </div>
                              </el-card>
                         </el-space>
                         <p v-if="music_loading" v-loading="music_loading" element-loading-text="Loading..."></p>
                         <p v-if="music_noMore"><el-divider><el-icon>
                                        <CoffeeCup />
                                   </el-icon></el-divider></p>
                    </el-scrollbar>
               </el-main>
          </el-container>
          <footer class="firstfooter">
          </footer>
     </el-container>
</template>

<script>
import 'aplayer/dist/APlayer.min.css';
import APlayer from 'aplayer';
import { reactive, ref, onMounted, onBeforeUnmount, computed } from 'vue';
import { musicReqApi } from '@/api/musicReq'
import { ElMessage } from 'element-plus'
import { CaretRight, Plus, CoffeeCup, Aim } from '@element-plus/icons-vue'
import { throttle } from '@/utils/tool'

export default {
     name: 'ListenMusic',
     components: {
          CaretRight, Plus, CoffeeCup, Aim
     },
     setup() {
          const drawer_querysong = ref(false);
          const IsPC = ref(true);//是否PC端访问
          const card_musicWidthVal = ref(150);
          const formRef = ref();
          const musicList = reactive({ list: [] });
          const music_loading = ref(false) //是否正在加载
          const all_data = ref(false) //是否加载了所有数据（分页取数据时判断得到）
          const music_noMore = computed(() => (musicList.list.length >= 80) || all_data.value) //是否加载全部(或设置的最大值)
          const music_disabled = computed(() => music_loading.value || music_noMore.value); //是否禁用加载
          const loadMusicList = throttle(function () {
               sizeForm.pageIndex += 1;
               //initTestData();
               musicReqApi(sizeForm).then((res) => {
                    //console.log('音乐清单', res)
                    musicList.list.push(...res.data);
                    if (res.data.length < sizeForm.pageSize) {
                         all_data.value = true;
                    }
                    music_loading.value = false;
                    if (musicList.list.length > 0) {
                         //查询结果不为空时 则隐藏查询抽屉
                         drawer_querysong.value = false;
                    }
               }).catch(error => {
                    console.log(error);
                    ElMessage.error(error.message);
               })
          }, 800);
          //通过调用 loadMusicList_pub 执行加载，不要直接调用 loadMusicList
          const loadMusicList_pub = (isfirstPage) => {
               if (isfirstPage) {
                    musicList.list.length = 0;
                    sizeForm.pageIndex = 0;
               }
               music_loading.value = true;
               loadMusicList();
          }
          const sizeForm = reactive({
               songName: '',
               singerName: '',
               albumName: '',
               pageIndex: 0,
               pageSize: 12,
          })
          //播放器初始数据
          const state = reactive({
               audio: [{
                    name: "屋顶",//歌名
                    artist: '周杰伦,温岚',//歌手
                    //url: 'https://www.ytmp3.cn/down/59298.mp3down/39868.mp3',//音频文件地址
                    url: '/music/屋顶.mp3',
                    cover: 'http://imge.kugou.com/stdmusic/150/20170815/20170815070007812976.jpg',//音乐封面地址
                    lrc: "/lrc/屋顶.txt"// 歌词
               }]
          });
          let ap = null;
          const addMyAudio = () => {
               ap = new APlayer({
                    container: document.getElementById("aplayer"),
                    lrcType: 3,
                    //fixed: true,
                    listFolded: true,
                    //listMaxHeight: 90,
                    audio: state.audio
               })
          };
          const playThisMusic = (music) => {
               //播放当前音乐
               ap.list.add(music);
               if (ap.list.audios.length > 0) {
                    ap.list.switch(ap.list.audios.length - 1);
               }
               ap.play();
          }
          const AddtoList = (music) => {
               ap.list.add(music);
          }
          const onSubmit = () => {
               //查询音乐
               all_data.value = false;
               loadMusicList_pub(true);
          }
          const resetForm = () => {
               //重置查询条件
               if (!formRef.value) return
               formRef.value.resetFields()
          }
          // const initTestData = () => {
          //      for (let index = 0; index < 15; index++) {
          //           musicList.list.push({
          //                name: "屋顶",
          //                artist: '周杰伦,温岚',
          //                url: '/music/屋顶.mp3',
          //                cover: 'http://imge.kugou.com/stdmusic/150/20170815/20170815070007812976.jpg',
          //                lrc: "/lrc/屋顶.lrc"
          //           });
          //      }
          //      music_loading.value = false;
          // }
          onMounted(() => {
               const isMobile = /Mobile/i.test(navigator.userAgent)
               IsPC.value = !isMobile;
               if (isMobile) { card_musicWidthVal.value = 125; }
               addMyAudio();//初始音乐组件
               loadMusicList_pub(true);//加载音乐列表
          });
          onBeforeUnmount(() => {
               ap.destroy();//销毁音乐组件
          });
          return {
               formRef,
               addMyAudio,
               sizeForm,
               onSubmit,
               resetForm,
               musicList,
               loadMusicList_pub,
               playThisMusic, AddtoList,
               music_loading, music_noMore, music_disabled,
               drawer_querysong,
               IsPC,
               card_musicWidthVal,
          };
     }
}
</script>

<style scoped>
.aplayer {
     z-index: 999;
}

.topContainer {
     height: calc(90vh - 120px);
}

.firstfooter {
     height: 20px;
}

.el-header {
     /* 
  position: relative;
  background-color: var(--el-color-primary-light-7);
  color: var(--el-text-color-primary); 
  */
     height: 100px;
}

.el-aside {
     padding-left: 20px;
     padding-top: 20px;
     padding-bottom: 20px;
     padding-right: 8px;
     /* width: 15%; */
     min-width: 160px;
     /* 设置 el-aside 最小宽度 */
     max-width: 200px;
     /* 设置 el-aside 最大宽度 */
     border-right: solid 0.05em silver;
}

.el-main {
     overflow: hidden;
}

.cardbottom {
     /* margin-top: 13px; */
     /* line-height: 12px; */
     display: flex;
     justify-content: space-around;
     /* align-items: center; */
}

/* .cardbottom .button {
     padding: 0;
} */

.querybutton {
     position: fixed;
     bottom: 20px;
     right: 20px;
     z-index: 1000;
     /* 确保置于顶层 */
}
@media (max-width: 767px) {
  .el-card {
    --el-card-padding: 6px;
  }

  .el-main{
     padding-left: 3px;
     padding-right: 3px;
  }
}
</style>

<style>
#singquery_dom_id{
     height: 33% !important;
}

#singquery_dom_id .el-drawer__body{
     padding: 5px;
}
#btn_ListenMusic_querySong .el-form-item__content{
     justify-content: center;
}
</style>