<template>
    
    <el-container>
          <el-header></el-header>
          <el-main>
               <el-empty description="网站风格" />
          </el-main>
          <el-footer></el-footer>
     </el-container>
</template>
<script lang="ts">

import { onMounted, onBeforeUnmount } from 'vue';

export default {
     name: 'ManageWebStyle',
     components: {
     },
     setup() {
          
          onMounted(() => {
               console.log("ManageWebStyle");
          });
          onBeforeUnmount(() => {

          });
          return {

          };
     }

}
</script>