<template>
    <!-- 【Admin Work 管理员工作室】页面左右结构 -->
    <el-container class="layout-container-demo" style="height:inherit;">
        <el-aside width="auto">
            <el-scrollbar>
                <!-- <el-radio-group v-model="isCollapse" style="margin-bottom: 20px" >
                <el-radio-button :label="false">展开</el-radio-button>
                <el-radio-button :label="true">收缩</el-radio-button>
                </el-radio-group> -->
                <el-menu default-active="ManageMusic" class="el-menu-vertical-demo" :collapse="isCollapse"
                    @open="handleOpen" @close="handleClose" router>
                    <!-- 
                        <el-sub-menu index="1">
                            <template #title>
                            <el-icon><Location/></el-icon>
                            <span>Navigator One</span>
                            </template>
                            <el-menu-item-group>
                            <template #title><span>Group One</span></template>
                            <el-menu-item index="1-1">item one</el-menu-item>
                            <el-menu-item index="1-2">item two</el-menu-item>
                            </el-menu-item-group> 
                        </el-sub-menu>
                    -->
                    <el-menu-item index="ManageMusic">
                        <el-icon>
                            <svg class="icon" aria-hidden="true">
                                <use xlink:href="#icon-chuizi1"></use>
                            </svg>
                        </el-icon>
                        <template #title>音乐维护</template>
                    </el-menu-item>
                    <el-menu-item index="ManageWebStyle">
                        <el-icon>
                            <!-- <MagicStick /> -->
                            <svg class="icon" aria-hidden="true">
                                <use xlink:href="#icon-mianjuhuazhuangwuhui"></use>
                            </svg>
                        </el-icon>
                        <template #title>网站风格</template>
                    </el-menu-item>
                </el-menu>
            </el-scrollbar>
        </el-aside>
        <router-view name="adminwork" v-slot="{ Component }">
            <keep-alive :include="keepAliveComponents">
                <component :is="Component" />
            </keep-alive>
        </router-view>
    </el-container>
</template>
  
<script lang="ts">
import { ref } from 'vue'
import { onMounted, onBeforeUnmount } from 'vue';
import { keepAliveComponents } from '../routes'
import { Headset, MagicStick } from '@element-plus/icons-vue'

export default {
    name: 'AdminWork',
    components: {
        Headset, MagicStick
    },
    setup() {
        //const cacheList = ref(["ListenMusic", "UploadMusic"])
        const isCollapse = ref(true)
        const handleOpen = (key: string, keyPath: string[]) => {
            console.log(key, keyPath)
        }
        const handleClose = (key: string, keyPath: string[]) => {
            console.log(key, keyPath)
        }
        onMounted(() => {
            console.log("AdminWork")
        });
        onBeforeUnmount(() => {

        });
        return {
            // cacheList,
            isCollapse,
            handleOpen,
            handleClose,
            keepAliveComponents
        };
    }

}
</script>
  
<style scoped>
.el-menu-vertical-demo:not(.el-menu--collapse) {
    width: 200px;
    min-height: 400px;
    margin-top: 20px;
}
</style>