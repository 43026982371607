<template>
    <!-- 【Music】页面左右结构 -->
    <el-container class="layout-container-demo" style="height:inherit;">
        <el-aside width="auto">
            <el-scrollbar>
                <!-- <el-radio-group v-model="isCollapse" style="margin-bottom: 20px" >
                <el-radio-button :label="false">展开</el-radio-button>
                <el-radio-button :label="true">收缩</el-radio-button>
                </el-radio-group> -->
                <el-menu :default-active="defaultActive" class="el-menu-vertical-demo" :collapse="isCollapse"
                    @open="handleOpen" @close="handleClose" router>
                    <!-- 
                        <el-sub-menu index="1">
                            <template #title>
                            <el-icon><Location/></el-icon>
                            <span>Navigator One</span>
                            </template>
                            <el-menu-item-group>
                            <template #title><span>Group One</span></template>
                            <el-menu-item index="1-1">item one</el-menu-item>
                            <el-menu-item index="1-2">item two</el-menu-item>
                            </el-menu-item-group> 
                        </el-sub-menu>
                    -->
                    <el-menu-item index="ListenMusic">
                        <!-- <el-icon class="is-loading">
                            <Headset />
                        </el-icon> -->
                        <el-icon class="is-loading">
                            <svg class="icon" aria-hidden="true" font-size="2em">
                                <use xlink:href="#icon-yinle1"></use>
                            </svg>
                        </el-icon>
                        <template #title>听音乐</template>
                    </el-menu-item>
                    <el-menu-item index="SearchMusic">
                        <el-icon>
                            <svg class="icon" aria-hidden="true" font-size="1.5em">
                                <use xlink:href="#icon-sousuohui"></use>
                            </svg>
                        </el-icon>
                        <template #title>搜歌词</template>
                    </el-menu-item>
                    <el-menu-item index="ChatMusic">
                        <!-- <el-icon>
                            <ChatDotRound /> 
                        </el-icon> -->
                        <el-icon>
                            <svg class="icon" aria-hidden="true" font-size="2em">
                                <use xlink:href="#icon-liaoyiliao"></use>
                            </svg>
                        </el-icon>
                        <template #title>聊音乐</template>
                    </el-menu-item>
                    <el-menu-item index="UploadMusic">
                        <el-icon style="color:cadetblue">
                            <!-- <UploadFilled /> -->
                            <svg class="icon" aria-hidden="true" font-size="1em">
                                <use xlink:href="#icon-ziliaoshangchuan"></use>
                            </svg>
                        </el-icon>
                        <template #title>上传音乐</template>
                    </el-menu-item>
                </el-menu>
            </el-scrollbar>
        </el-aside>
        <router-view v-slot="{ Component }">
            <keep-alive :include="keepAliveComponents">
                <component :is="Component" :key="$route.path" />
            </keep-alive>
        </router-view>
    </el-container>
</template>
  
<script lang="ts">
import { ref } from 'vue'
import { onMounted, onBeforeUnmount } from 'vue';
import { keepAliveComponents } from '../routes'
import { Headset, ChatDotRound, Search, UploadFilled } from '@element-plus/icons-vue'

export default {
    name: 'Music',
    components: {
        Headset, ChatDotRound, Search, UploadFilled
    },
    setup() {
        const defaultActive = ref('')
        //const defaultOpen = ref(['ChatMusic'])
        const isCollapse = ref(true)
        const handleOpen = (key: string, keyPath: string[]) => {
            console.log(key, keyPath)
        }
        const handleClose = (key: string, keyPath: string[]) => {
            console.log(key, keyPath)
        }
        onMounted(() => {
            defaultActive.value = 'ListenMusic'
            console.log("Music")
        });
        onBeforeUnmount(() => {

        });
        return {
            // cacheList,
            isCollapse,
            handleOpen,
            handleClose,
            keepAliveComponents,
            //defaultOpen,
            defaultActive,
        };
    }

}
</script>
  
<style>
.el-menu-vertical-demo:not(.el-menu--collapse) {
    width: 200px;
    min-height: 400px;
    margin-top: 20px;
}
</style>