<template>
     <el-container>
          <div class="bg-image">
               <el-header></el-header>
               <el-main>
                    <div id="div_search_id">
                         <el-input v-model="txt_Search" maxlength="20" placeholder="请输入歌词 (示例：你好周杰伦)" @keyup.enter="DoSearch_pub"
                              show-word-limit type="text" clearable>
                              <template #append>
                                   <el-button :icon="Search" @click="DoSearch_pub" />
                              </template>
                         </el-input>
                    </div>
                    <el-container>
                         <el-main id="SearchMusic_main_result_id" element-loading-background="rgba(188, 221, 202, 0.11)"
                              v-loading="search_loading">
                              <el-scrollbar>
                                   <el-row v-for="oneresult in resultList.list" :key="oneresult">
                                        <el-col :span="24">
                                             <div class="grid-content ep-bg-purple-dark">
                                                  <el-scrollbar>
                                                       <el-text v-html="ConvertResult(oneresult)"></el-text>
                                                       <!-- <el-text truncated>
                                                       {{ oneresult.contents }}
                                                  </el-text> -->
                                                  </el-scrollbar>
                                             </div>
                                        </el-col>
                                   </el-row>
                                   <!-- <p v-if="search_loading" v-loading="search_loading" element-loading-text="Loading..."></p> -->
                              </el-scrollbar>
                         </el-main>
                    </el-container>
               </el-main>
               <el-footer></el-footer>
          </div>
     </el-container>
</template>
   

<script lang="ts">

import { onMounted, onBeforeUnmount, ref, reactive } from 'vue';
import { Search } from '@element-plus/icons-vue'
import { throttle } from '@/utils/tool'
import { SearchSubtitle } from '@/api/musicReq'
export default {
     name: 'SearchMusic',
     components: {

     },
     setup() {
          interface Song {
               id: string;
               name: string;
               videoId: string;
               contents: string;
          }
          const searchForm = reactive({
               Keyword: "", //歌词
               PageIndex: 1,
               PageSize: 5,
          })
          const txt_Search = ref('');
          const search_loading = ref(false);
          //const txt_Search_notempty = computed(() => txt_Search.value.length > 0)
          const resultList = reactive<{ list: Song[] }>({ list: [] });
          const DoSearch_pub = () => {
               resultList.list.length = 0;
               if (!txt_Search.value) {
                    search_loading.value = false;
                    return;
               }
               search_loading.value = true;
               DoSearch();
          }

          const DoSearch = throttle(function () {
               //alert(txt_Search.value);               
               searchForm.Keyword = txt_Search.value;
               SearchSubtitle(searchForm).then((res: any) => {
                    if (res) {
                         //console.log("查询歌词", res);
                         resultList.list.push(...res.subtitles);
                    }
               }).catch(error => {
                    console.log(error);
               });
               // for (let index = 0; index < 2; index++) {
               //      resultList.list.push({
               //           id: "",
               //           name: "屋顶",
               //           artist: '周杰伦,温岚',
               //           lrc: "测试歌词测试歌词测试歌词测试歌词测试歌测试歌111词测试歌词测试歌词测试歌词测试词222词测试歌词测试歌词测试歌词测试词333词测试歌词测试歌词测试歌词测试词444"
               //      });
               // }
               search_loading.value = false;
          }, 700)
          const ConvertResult = (oneresult: Song) => {
               let target = '<b>《' + oneresult.name + '》</b><br/>' + oneresult.contents
               target = target.replaceAll('<em>', '<font color="red">')
               target = target.replaceAll('</em>', '</font>')
               return target;
          }
          onMounted(() => {
               console.log("SearchMusic");
          });
          onBeforeUnmount(() => {

          });
          return {
               Search
               , DoSearch_pub
               , txt_Search
               , resultList
               , search_loading
               , ConvertResult
          };
     }

}
</script>

<style scoped>
.bg-image {
     /* 引用背景图片 */
     background-image: url(~@/assets/bgimage/bk-music-search-pc.jpg);
     background-repeat: no-repeat;
     background-position: center center;
     background-size: cover;
     /* 设置其他的样式规则 */
     width: 100%;
     height: 100%;
}

@media (max-width: 767px) {
     .bg-image {
          background-image: url(~@/assets/bgimage/bk-music-search-mob.jpeg) !important;
     }
}

#SearchMusic_main_result_id {
     height: 60vh;
     /* overflow-y: scroll; */
}

#div_search_id {
     margin-left: 20px;
     margin-right: 20px;
}
</style>


<style lang="scss">
#SearchMusic_main_result_id .el-row {
     margin-bottom: 5px;
     height: 120px
}

#SearchMusic_main_result_id .el-row:last-child {
     margin-bottom: 0;
}

#SearchMusic_main_result_id .el-col {
     border-radius: 4px;
}

#SearchMusic_main_result_id .grid-content {
     border-radius: 4px;
     min-height: 36px;
     /* 文字水平、垂直居中、居中对齐 */
     display: flex;
     justify-content: center;
     align-items: center;
     text-align: center;
}

#SearchMusic_main_result_id .ep-bg-purple-dark {
     // background: #f1f1f5;
     background-color: rgba(188, 221, 202, 0.71);
     height: 100%;
     max-height: 120px;
     white-space: pre-wrap;
     word-break: break-word;
}
</style>