<template>
     <el-container>
          <el-header></el-header>
          <el-main>
               <el-upload class="upload-demo" drag 
               :http-request="goupload" action="" 
               :auto-upload="true" 
               multiple>
                    <el-icon class="el-icon--upload"><upload-filled /></el-icon>
                    <div class="el-upload__text">
                         拖拽上传、<em>点击上传</em>
                    </div>
                    <template #tip>
                         <div class="el-upload__tip">
                              限制 10mb (mp3、lrc)
                         </div>
                    </template>
               </el-upload>

          </el-main>
          <el-footer></el-footer>
     </el-container>
</template>

<script lang="ts">

import { onMounted, onBeforeUnmount } from 'vue';
import { UploadFilled } from '@element-plus/icons-vue'
import { UploadSong } from '@/api/musicReq'
import { ElMessage } from 'element-plus'

export default {
     name: 'UploadMusic',
     components: {
          UploadFilled,
     },
     setup() {
          const goupload = (file: any) => {
               UploadSong(file).then(() => {
                    ElMessage.success('上传成功')
                    //console.log(res);
                    return false;
               }).catch(() => { //移除了error 参数
                    return false;
               });
          };
          onMounted(() => {
               console.log("UploadMusic");
          });
          onBeforeUnmount(() => {

          });
          return {
               goupload
          };
     }

}
</script>
