<template>
    <div class="bg-image">
        <!-- your webpage content -->
    </div>
</template>

<script>
export default {
    // 省略其它选项
    //   beforeCreate() {
    //     require('@/assets/img/registback.jpg')
    //   }
}
</script>

<style scoped>
.bg-image {
    /* 引用背景图片 */
    background-image: url(~@/assets/bgimage/bk-changepass-pc.jpg);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    /* 设置其他的样式规则 */
    width: 100%;
    height: 100%;
}

@media (max-width: 767px) {
.bg-image {
     background-image: url(~@/assets/bgimage/bk-changepass-mob.jpg) !important;
}
}
</style>