import { ref } from 'vue'

const IsMobile = () => {
     return /Mobile/i.test(navigator.userAgent);
}

//节流函数封装（单位时间内，多次请求，仅执行第一次）
//js版本
// const throttle = function (fn, delay) {
//     let id = null;// 利用闭包让id不会被回收
//     return function () {
//          if (id === null) {
//               // id为null时设置定时器
//               id = setTimeout(() => {
//                    fn(...arguments);
//                    id = null; //函数执行完 id赋null
//               }, delay);
//          }
//     };
// };
//Ts版本 (待测试：id 冲突问题)
/* 节流 */
const throttle = function <T extends any[]>(fn: (...args: T) => void, delay: number) {
     let id: ReturnType<typeof setTimeout> | null = null;

     return function (...args: T) {
          if (id === null) {
               id = setTimeout(() => {
                    fn(...args);
                    id = null;
               }, delay);
          }
     };
};
//判断是否是图片
const IsPicture = function (fileName: String) {
     const allowedSuffixes = ['.jpg', '.jpeg', '.png', '.gif', '.ico'];
     const fileSuffix = fileName.substring(fileName.lastIndexOf('.'));
     return allowedSuffixes.includes(fileSuffix.toLowerCase());
}
//判断是否是字幕文件
const IsCaptions = function (fileName: String) {
     const allowedSuffixes = ['.srt', '.ass', '.sub', '.txt', '.vtt', '.lrc'];
     const fileSuffix = fileName.substring(fileName.lastIndexOf('.'));
     return allowedSuffixes.includes(fileSuffix.toLowerCase());
}
//判断是否是音频文件
const IsAudio = function (fileName: String) {
     const allowedSuffixes = ['.mp3', '.mp4', '.m4a', '.wav', '.wma', '.cda', '.mov'];
     const fileSuffix = fileName.substring(fileName.lastIndexOf('.'));
     return allowedSuffixes.includes(fileSuffix.toLowerCase());
}
const getFilePreName = function (fileName: String) {
     return fileName.substring(0, fileName.lastIndexOf('.'));
}
export { IsMobile, throttle, IsPicture, IsCaptions, IsAudio, getFilePreName }