import request from './request'

//查询歌曲
const musicReqApi = (data:any) => {
    return request.post({
        baseURL: request.GetTargetUrl(request.serviceName.Listening),
        url: '/Song/GetSongs',
        data
    })
}
//添加歌曲
const AddSong = (data:any) => {
    return request.post({
        baseURL: request.GetTargetUrl(request.serviceName.Listening),
        url: '/Song/AddSong',
        data
    })
}
//上传附件
const UploadSong = (data:any) => {
    return request.post({
        baseURL: request.GetTargetUrl(request.serviceName.File),
        url: '/Uploader/Upload',
        data,
        headers: {
            'Content-Type': "	multipart/form-data"
        },
    })
}
//获取附件访问地址
const GetUrlsByFileName = (data:any) => {
    return request.get({
        baseURL: request.GetTargetUrl(request.serviceName.File),
        url: '/Uploader/GetUrlsByFileName?fileName='+data,
    })
}
//获取歌手
const GetSingers = (data:any) => {
    return request.get({
        baseURL: request.GetTargetUrl(request.serviceName.Listening),
        url: '/Singer/GetSinger?singerName='+data,
    })
}
//添加歌手
const AddSinger = (data:any) => {
    return request.post({
        baseURL: request.GetTargetUrl(request.serviceName.Listening),
        url: '/Singer/AddSinger',
        data,
    })
}
//查询歌词
const SearchSubtitle = (data:any) => {
    return request.get({
        baseURL: request.GetTargetUrl(request.serviceName.Search),
        url: '/Search/SearchSubtitle',
        data,
    })
}
export {
    musicReqApi,
    AddSong,
    UploadSong,
    GetUrlsByFileName,
    GetSingers,
    AddSinger,
    SearchSubtitle,
}