import login from './request'

const loginApi = data => {
    return login.post({
        baseURL: login.GetTargetUrl(login.serviceName.Identity),
        url: '/Login/LoginByUserNameAndPwd',
        data
    })
}

const registerApi = data => {
    return login.post({
        baseURL: login.GetTargetUrl(login.serviceName.Identity),
        url: '/Login/AddUser',
        data
    })
}

export default {
    loginApi,
    registerApi
}