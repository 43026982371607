<template>
     <el-container>
          <div class="bg-image">
               <el-header></el-header>
               <div class="div_main_container">
                    <el-main class="div_el_main">
                         <el-container ref="scrollbarRef">
                              <el-main id="ChatMusic_main_content_id" element-loading-background="rgba(188, 221, 202, 0.11)">
                                   <el-scrollbar id="scrollContainer">
                                        <el-row v-for="onemessage in messageList.list" :key="onemessage">
                                             <!-- <el-scrollbar> -->
                                             <div class="el-col el-col-24" v-html="ConvertMessage(onemessage)"></div>
                                             <!-- </el-scrollbar> -->
                                        </el-row>
                                   </el-scrollbar>
                              </el-main>
                         </el-container>
                         <div id="div_search_id">
                              <el-input v-model="txt_Message" maxlength="80" placeholder="新消息" @keyup.enter="DoPublish_pub"
                                   show-word-limit clearable :rows="2" type="text">
                                   <template #append>
                                        <el-button @click="DoPublish_pub">
                                             <el-icon>
                                             <svg class="icon" aria-hidden="true">
                                                  <use xlink:href="#icon-drxx46"></use>
                                             </svg>
                                        </el-icon>
                                        </el-button>
                                   </template>
                              </el-input>
                         </div>
                    </el-main>
               </div>
               <el-footer></el-footer>
          </div>
     </el-container>
</template>

<script lang="ts">

import { onMounted, onBeforeUnmount, ref, reactive, nextTick } from 'vue';
// import { Search } from '@element-plus/icons-vue'
import { throttle } from '@/utils/tool'
import { useAuthStore } from '@/store/auth';

export default {
     name: 'ChatMusic',
     components: {
     },
     setup() {
          interface MessageInfo {
               id: string;
               sendUser: string;
               message: string;
               sendTime: string;
          }
          const messageForm = reactive({
               Message: "", //新信息
          })
          const scrollbarRef = ref(null);
          const store = useAuthStore();
          const username = store.$state.userInfo?.username;
          const txt_Message = ref('');
          //const search_loading = ref(false);
          const messageList = reactive<{ list: MessageInfo[] }>({ list: [] });
          const DoPublish_pub = () => {
               if (!txt_Message.value) {
                    //search_loading.value = false;
                    return;
               }
               //search_loading.value = true;
               DoPublish();
          }

          const DoPublish = throttle(function () {
               messageForm.Message = txt_Message.value;
               // SearchSubtitle(messageForm).then((res: any) => {
               //      if (res) {
               //           messageList.list.push(...res.subtitles);
               //      }
               // }).catch(error => {
               //      console.log(error);
               // });
               messageList.list.push({
                    id: "",
                    sendUser: messageList.list.length % 2 == 0 ? 'admin' : 'root',
                    message: txt_Message.value,
                    sendTime: "2023-06-26 12:30:23"
               });
               txt_Message.value = '';
               scrollToBottom();
               //search_loading.value = false;
          }, 500)
          const ConvertMessage = (onemessage: MessageInfo) => {
               let LorR = username == onemessage.sendUser ? 'right' : 'left';
               let target = '';
               let sroll_begin = '';
               let sroll_end = '';
               sroll_begin += '<div class="el-scrollbar">';
               sroll_begin += '<div class="el-scrollbar__wrap el-scrollbar__wrap--hidden-default">';
               sroll_begin += '<div class="el-scrollbar__view" style="">';
               sroll_end += '</div></div></div>';

               target += '<div style="justify-content:' + LorR + '" class="grid-content ep-bg-purple-dark" >'
               if (username == onemessage.sendUser) {
                    target += sroll_begin;
                    target += '<div class="selfmessage"><span>' + onemessage.message + '</span></div>';
                    target += sroll_end;
                    target += '<div><span>' + " : " + onemessage.sendUser + '</span></div>';

               } else {
                    target += '<div><span>' + onemessage.sendUser + " : " + '</span></div>';
                    target += sroll_begin;
                    target += '<div class="othermessage"><span>' + onemessage.message + '</span></div>';
                    target += sroll_end;
               }
               target += '</div>';
               return target;
          }
          const scrollToBottom = () => {
               const scrollbar = scrollbarRef.value as any;
               if (scrollbar && scrollbar.$el) {
                    nextTick(() => {
                         const elScrollbar = scrollbar.$el.getElementsByClassName('el-scrollbar__wrap')[0];
                         elScrollbar.scrollTop = elScrollbar.scrollHeight;
                    });
               }
          }
          onMounted(() => {
               //console.log("ChatMusic");
          });
          onBeforeUnmount(() => {

          });
          return {
               //Search
               DoPublish_pub
               , txt_Message
               , messageList
               , ConvertMessage
               , scrollbarRef
          };
     }

}
</script>

<style scoped>
.bg-image {
     /* 引用背景图片 */
     background-image: url(~@/assets/bgimage/bk-chat-pc.jpg);
     background-repeat: no-repeat;
     background-position: center center;
     background-size: cover;
     /* 设置其他的样式规则 */
     width: 100%;
     height: 100%;
}

@media (max-width: 767px) {
     .bg-image {
          background-image: url(~@/assets/bgimage/bk-chat-mob.jpg) !important;
     }
}

.div_main_container {
     display: flex;
     justify-content: center;
}

.div_el_main {
     background-color: rgba(219, 233, 235, 0.71);
     max-width: 700px;
     /* 调整 el-main 的最大宽度 */
     margin: 0 auto;
     /* 通过设置 margin 属性实现水平居中 */
}

#ChatMusic_main_content_id {
     height: 60vh;
     /* width: 80vh; */
     /* overflow-y: scroll; */
}

#div_search_id {
     margin-left: 20px;
     margin-right: 20px;
}
</style>

<style lang="scss">
#ChatMusic_main_content_id .el-row {
     margin-bottom: 5px;
     height: auto;
}

#ChatMusic_main_content_id .el-row:last-child {
     margin-bottom: 0;
}

#ChatMusic_main_content_id .el-col {
     border-radius: 4px;
}

#ChatMusic_main_content_id .grid-content {
     border-radius: 4px;
     min-height: 36px;
     padding: 5px;
     /* 文字水平、垂直居中、居中对齐 */
     display: flex;
     //justify-content: center;
     align-items: center;
     //text-align: center;
}

#ChatMusic_main_content_id .ep-bg-purple-dark {
     // background: #f1f1f5;
     //background-color: rgba(219, 233, 235, 0.71);
     height: 100%;
     max-height: 120px;
     white-space: pre-wrap;
     word-break: break-word;
}

#ChatMusic_main_content_id .selfmessage {
     // background: #f1f1f5;
     background-color: rgba(122, 234, 120, 0.71);
     padding: 10px;
     border-radius: 8px;
     max-height: auto;
     max-width: 230px;
}

#ChatMusic_main_content_id .othermessage {
     // background: #f1f1f5;
     background-color: rgba(148, 222, 230, 0.71);
     padding: 10px;
     border-radius: 8px;
     max-height: auto;
     max-width: 230px;
}
</style>