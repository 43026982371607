<template>
    <el-container>
        <el-header></el-header>
        <el-main id="manageMusic_main_dom_id">
            <el-tabs type="border-card" class="demo-tabs">
                <el-tab-pane>
                    <template #label>
                        <span class="custom-tabs-label">
                            <el-icon>
                                <calendar />
                            </el-icon>
                            <span>添加歌曲</span>
                        </span>
                    </template>
                    <el-form id="addsongform_dom_id" ref="ruleFormRef" :model="ruleForm" :rules="rules" label-width="70px"
                        class="demo-ruleForm" :size="formSize" status-icon style="height:65vh;overflow-y: scroll;">
                        <el-form-item label="歌名" prop="SongName">
                            <el-input v-model="ruleForm.SongName" @keyup.enter="FillForm" />
                        </el-form-item>
                        <!-- <el-form-item label="Activity zone" prop="region">
                    <el-select v-model="ruleForm.region" placeholder="Activity zone">
                        <el-option label="Zone one" value="shanghai" />
                        <el-option label="Zone two" value="beijing" />
                    </el-select>
                </el-form-item> -->
                        <el-form-item label="艺人" prop="SingerName">
                            <el-input v-model="ruleForm.SingerName" />
                        </el-form-item>
                        <el-form-item label="专辑" prop="SongAlbum">
                            <el-input v-model="ruleForm.SongAlbum" />
                        </el-form-item>
                        <el-form-item label="歌曲" prop="AudioUrl">
                            <el-input v-model="ruleForm.AudioUrl" placeholder="url" />
                        </el-form-item>
                        <el-form-item label="封面" prop="CoverUrl">
                            <el-input v-model="ruleForm.CoverUrl" placeholder="url" />
                        </el-form-item>
                        <el-form-item label="歌词" prop="SubtitleUrl">
                            <el-input v-model="ruleForm.SubtitleUrl" placeholder="url" />
                        </el-form-item>
                        <el-form-item label="生效" prop="IsVisible">
                            <el-switch v-model="ruleForm.IsVisible" />
                        </el-form-item>
                        <el-form-item label="描述" prop="Desc">
                            <el-input v-model="ruleForm.Desc" type="textarea" />
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" @click="submitForm(ruleFormRef)">
                                Create
                            </el-button>
                            <el-button @click="resetForm(ruleFormRef)">Reset</el-button>
                        </el-form-item>
                    </el-form>
                </el-tab-pane>
                <el-tab-pane label="艺人">
                    <el-table :data="singerData.list" style="width: 100%;" height="65vh" v-loading="loading_singer">
                        <el-table-column v-if="IsPCScreen" type="index"></el-table-column>
                        <!-- <el-table-column label="ID" prop="id"/> -->
                        <el-table-column v-if="IsPCScreen" label="添加时间" prop="creationTime" />
                        <el-table-column label="中文名" prop="name.chinese" />
                        <el-table-column v-if="IsPCScreen" label="英文名" prop="name.english" />

                        <el-table-column align="right">
                            <template #header>
                                <el-input v-model="searchSinger" size="small" placeholder="搜索艺人"
                                    @keyup.enter="before_DoSearchSinger" />
                                <el-button size="small" type="success" @click="ShowAddSinger()">添加</el-button>
                            </template>
                            <template #default="scope">
                                <el-button size="small" @click="SingerEdit(scope.$index, scope.row)">编辑</el-button>
                                <el-button size="small" type="danger"
                                    @click="SingerDelete(scope.$index, scope.row)">删除</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-tab-pane>
                <el-tab-pane label="Role">Role</el-tab-pane>
                <el-tab-pane label="Task">Task</el-tab-pane>
            </el-tabs>
        </el-main>
        <el-footer></el-footer>
    </el-container>
    <!-- 添加歌手 -->
    <el-dialog v-model="dialogFormVisible_addsinger" title="添加歌手">
        <el-form :model="form_singer">
            <el-form-item label="中文名" :label-width="formLabelWidth_addsinger">
                <el-input v-model="form_singer.Name.Chinese" autocomplete="off" />
            </el-form-item>
            <el-form-item label="英文名" :label-width="formLabelWidth_addsinger">
                <el-input v-model="form_singer.Name.English" autocomplete="off" />
            </el-form-item>
        </el-form>
        <template #footer>
            <span class="dialog-footer">
                <el-button @click="dialogFormVisible_addsinger = false">取消</el-button>
                <el-button type="primary" @click="DoAddSinger">
                    保存
                </el-button>
            </span>
        </template>
    </el-dialog>
</template>

<script lang="ts">

import { onMounted, onBeforeUnmount } from 'vue';
import { reactive, ref } from 'vue'
import type { FormInstance, FormRules } from 'element-plus'
import { AddSong, GetUrlsByFileName, GetSingers, AddSinger } from '@/api/musicReq'
import { ElMessage } from 'element-plus'
import { IsMobile, throttle, IsPicture, IsCaptions, IsAudio, getFilePreName } from '@/utils/tool'
import { Calendar } from '@element-plus/icons-vue'

export default {
    name: 'ManageMusic',
    components: {
        Calendar
    },
    setup() {
        const IsPCScreen = ref(true);
        const loading_singer = ref(false);
        const formSize = ref('default')
        const ruleFormRef = ref<FormInstance>()
        const ruleForm = reactive({
            SongName: "", //歌名
            Name: { Chinese: "", English: "" },
            SingerName: '周杰伦',//艺人
            SongAlbum: '',//专辑
            AudioUrl: '', //歌曲地址
            CoverUrl: '', //封面地址
            SubtitleUrl: '', //歌词地址
            IsVisible: true,
            Desc: '',
        })

        const rules = reactive<FormRules>({
            SongName: [
                { required: true, message: '歌名不可为空', trigger: 'blur' },
                { max: 20, message: '长度超长', trigger: 'blur' },
            ],
            SingerName: [{ required: true, message: '艺人不可为空', trigger: 'change', },],
            //SongAlbum: [{ required: true, message: '专辑不可为空', trigger: 'change', },],
            AudioUrl: [{ required: true, message: '歌曲地址不可为空', trigger: 'change', },],
            CoverUrl: [{ required: true, message: '封面地址不可为空', trigger: 'change', },],
            SubtitleUrl: [{ required: true, message: '歌词不可为空', trigger: 'change', },],
            Desc: [{ required: false, message: '', trigger: 'blur' },],
        })

        const submitForm = async (formEl: FormInstance | undefined) => {
            ruleForm.Name.Chinese = ruleForm.SongName;
            ruleForm.Name.English = ruleForm.SongName;
            console.log(ruleForm)
            if (!formEl) return
            await formEl.validate((valid, fields) => {
                if (valid) {
                    console.log('submit!');
                    AddMusic();
                } else {
                    console.log('error submit!', fields)
                }
            })
        }
        //输入歌名后自动填充（封面、歌词、歌曲 url）
        const FillForm = throttle(function () {
            GetUrlsByFileName(ruleForm.SongName).then((res) => {
                if (res) {
                    const urls = res.data;
                    for (let index = 0; index < urls.length; index++) {
                        const filename = urls[index].fileName;
                        const remoteurl = urls[index].remoteUrl;
                        if (IsPicture(filename)) {
                            ruleForm.CoverUrl = remoteurl;
                        } else if (IsCaptions(filename)) {
                            ruleForm.SubtitleUrl = remoteurl;
                        } else if (IsAudio(filename)) {
                            ruleForm.AudioUrl = remoteurl;
                            if (ruleForm.SongName != getFilePreName(filename)) {
                                ruleForm.SongName = getFilePreName(filename);
                            }
                        }
                    }
                }
            }).catch(error => {
                console.log(error);
                //ElMessage.error(error.message);
            })
        }, 500);
        const resetForm = (formEl: FormInstance | undefined) => {
            if (!formEl) return
            formEl.resetFields()
        }

        const AddMusic = throttle(function () {
            AddSong(ruleForm).then((res) => {
                if (res) {
                    ElMessage.success("已添加");
                }
            }).catch(error => {
                console.log(error);
                //ElMessage.error(error.message);
            })
        }, 1500);
        // const options = Array.from({ length: 10000 }).map((_, idx) => ({
        //     value: `${idx + 1}`,
        //     label: `${idx + 1}`,
        // }))
        //【【【【【【【【【【【【  艺人维护  】】】】】】】】】】】】】】】】
        const singerData: { list: any[] } = reactive({ list: [] });
        const searchSinger = ref('')
        //查询歌手
        const before_DoSearchSinger = ()=>{
            loading_singer.value=true;
            DoSearchSinger();
        }
        const DoSearchSinger = throttle(function () {
            singerData.list.length = 0;
            GetSingers(searchSinger.value).then((res) => {
                if (res) {
                    console.log("查询艺人成功", res);
                    singerData.list.push(...res.data);
                }
            }).catch(error => {
                console.log(error);
                ElMessage.error(error.message);
            });
            loading_singer.value=false;
        }, 800);
        const ShowAddSinger = () => {
            //弹出添加歌手弹窗
            dialogFormVisible_addsinger.value = true;
        }
        //调用接口添加歌手
        const DoAddSinger = throttle(function () {
            AddSinger(form_singer.Name).then((res) => {
                if (res) {
                    ElMessage.success("添加艺人成功");
                    dialogFormVisible_addsinger.value = false;
                    before_DoSearchSinger();
                }
            }).catch(error => {
                console.log(error);
                //ElMessage.error(error.message);
            })
        }, 1000);
        const SingerEdit = (index: number, row: any) => {
            console.log(index, row)
        }
        const SingerDelete = (index: number, row: any) => {
            console.log(index, row)
        }
        //添加歌手
        const dialogFormVisible_addsinger = ref(false)
        const formLabelWidth_addsinger = ref('100px')

        const form_singer = reactive({
            Name: { Chinese: "", English: "" }
        })
        onMounted(() => {
            console.log("ManageMusic");
            IsPCScreen.value = !IsMobile();
            if (!IsPCScreen.value) {
                formLabelWidth_addsinger.value = '60px'
            }
            before_DoSearchSinger();
        });
        onBeforeUnmount(() => {

        });
        return {
            IsPCScreen,
            ruleForm,
            submitForm,
            resetForm,
            formSize,
            ruleFormRef,
            rules,
            FillForm,

            searchSinger,
            singerData,
            SingerEdit,
            SingerDelete,
            ShowAddSinger,
            DoAddSinger,
            before_DoSearchSinger,
            loading_singer,
            dialogFormVisible_addsinger,
            formLabelWidth_addsinger,
            form_singer,
        };
    }

}
</script>

<style scoped>
.el-header {
    height: 1px;
}

.el-footer {
    height: 20px;
}

.demo-tabs>.el-tabs__content {
    padding: 32px;
    color: #6b778c;
    font-size: 32px;
    font-weight: 600;
}

.demo-tabs .custom-tabs-label .el-icon {
    vertical-align: middle;
}

.demo-tabs .custom-tabs-label span {
    vertical-align: middle;
    margin-left: 4px;
}

#manageMusic_main_dom_id{
    overflow-y: hidden;
}
</style>
<style>
/* #addsongform_dom_id .el-form-item__content{
    max-width: 300px;
} */

@media (max-width: 767px) {

    /* PC端70px 移动端 55px */
    #addsongform_dom_id .el-form-item__label {
        width: 55px !important;
    }
}

</style>