import { createRouter, createWebHashHistory } from 'vue-router'
import { useAuthStore } from '@/store/auth';
import UserCenter from './views/UserCenter.vue'
import ChangePassWord from './views/ChangePassWord.vue'
import Music from './views/Music.vue'
import ListenMusic from './views/music/ListenMusic.vue'
import SearchMusic from './views/music/SearchMusic.vue'
import ChatMusic from './views/music/ChatMusic.vue'
import UploadMusic from './views/music/UploadMusic.vue'
import AdminWork from './views/AdminWork.vue'
import ManageMusic from './views/admin/ManageMusic.vue'
import ManageWebStyle from './views/admin/ManageWebStyle.vue'
import { ref } from 'vue';

const keepAliveComponents = ref(['Music', 'ListenMusic','AdminWork','ManageMusic','SearchMusic','ChatMusic']);////

const router = createRouter({
  //createWebHashHistory()
  //createWebHistory()//'music'
  history: createWebHashHistory(),
  routes: [
    { path: '/login', name: 'Login', component: () => import('@/views/Login.vue') },
    { path: '/', component: () => import('@/views/Login.vue') },
    {
      path: '/home', name: 'Home', component: () => import('@/views/Home.vue'), meta: { requireAuth: true },
      children: [
        { path: '/UserCenter', name: 'UserCenter', component: UserCenter },
        { path: '/ChangePassWord', name: 'ChangePassWord', component: ChangePassWord },
        {
          path: '/Music', name: 'Music', component: Music,
          children: [
            { path: '/ListenMusic', name: 'ListenMusic', component: ListenMusic },
            { path: '/SearchMusic', name: 'SearchMusic', component: SearchMusic },
            { path: '/ChatMusic', name: 'ChatMusic', component: ChatMusic },
            { path: '/UploadMusic', name: 'UploadMusic', component: UploadMusic },
          ]
        },
        {
          path: '/AdminWork', name: 'AdminWork', component: AdminWork,
          children: [
            { path: '/ManageMusic', name: 'ManageMusic', components: { adminwork: ManageMusic } },
            { path: '/ManageWebStyle', name: 'ManageWebStyle', components: { adminwork: ManageWebStyle } },
          ]
        }
      ]
    },
  ]
})

// 在全局前置守卫中检查是否登录
router.beforeEach((to, from, next) => {
  if (to.meta.requireAuth && !isAuthenticated()) {
    console.log("检测到未登录")
    next({ name: 'Login' });
    //next('/login')
  } else {
    next()
  }
})

function isAuthenticated() {
  const authStore = useAuthStore();
  const token = authStore.token;
  // 解码 token 并检查其是否已过期
  if (!token) {
    // 如果找不到 token，则认为它已经过期
    return false;
  }
  const decodedToken = JSON.parse(atob(token.split('.')[1]))
  const expirationTime = decodedToken.exp * 1000
  console.log("token过期",Date.now() > expirationTime)
  if(Date.now() > expirationTime){
    return false;
  }
  return true;
  // 判断用户是否已经登录
  //return authStore.isLoggedin;
}

export { router, keepAliveComponents }