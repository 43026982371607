import Service from "./config";

//封装get请求
const get = (config) => {
    return Service({
        ...config,          //将 config 对象展开至 axios 的 request config 中
        method: 'get',      //确保使用 get 方法
        params: config.data //将传入的 data 属性作为查询参数添加到 GET 请求中，
    })
}

//封装post请求
const post = (config) => {
    return Service({
        ...config,
        method: 'post',
        data: config.data
    })
}

const serviceName = {
    Identity: 'Identity',
    Listening: 'Listening',
    File:'File',
    Search:'Search',
};

const GetTargetUrl = valName => {
    switch (valName) {
        case serviceName.Identity:
            return 'http://www.bandaoshijie.cn/IdentityService';
        case serviceName.File:
            return 'http://www.bandaoshijie.cn/FileService';
            //return 'http://localhost:5122';
        case serviceName.Listening:
            return 'http://www.bandaoshijie.cn/Listening';
            //return 'http://localhost:5252';
        case serviceName.Search:
            return 'http://www.bandaoshijie.cn/SearchService';
            //return 'http://localhost:5252';
        default:
            return 'http://www.bandaoshijie.cn/IdentityService';
    }
}

const GetWebSiteBackground =()=>{
    //yisha admin 后台
    return 'http://www.bandaoshijie.cn/admin';
}

export default {
    get,
    post,
    GetTargetUrl,
    serviceName,
    GetWebSiteBackground
}