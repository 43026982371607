import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_header = _resolveComponent("el-header")!
  const _component_el_empty = _resolveComponent("el-empty")!
  const _component_el_main = _resolveComponent("el-main")!
  const _component_el_footer = _resolveComponent("el-footer")!
  const _component_el_container = _resolveComponent("el-container")!

  return (_openBlock(), _createBlock(_component_el_container, null, {
    default: _withCtx(() => [
      _createVNode(_component_el_header),
      _createVNode(_component_el_main, null, {
        default: _withCtx(() => [
          _createVNode(_component_el_empty, { description: "网站风格" })
        ]),
        _: 1
      }),
      _createVNode(_component_el_footer)
    ]),
    _: 1
  }))
}