import { createApp } from 'vue'
import App from './App.vue'
import {router} from './routes'
import { setupStore } from './store'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import '@/assets/icon/iconfont.js' 

// //导入路由
// import axios from 'axios';
const app = createApp(App)
//全局设置路由
// app.config.globalProperties.axios=axios

//全局引入图标
//全局引入图标
//全局引入图标
//import { Edit,Avatar } from "@element-plus/icons-vue";
//app.component("EditCom",Edit)
//app.component("AvatarCom",Avatar) 
//挂载路由
app.use(router)
app.use(ElementPlus)
setupStore(app);//pinia(持久化) 状态管理
app.mount('#app')
