<template>
    <div class="bg-image">
        <!-- your webpage content -->
        <el-card class="box-card" shadow="hover">
            <div class="text item">欢迎：{{ username }}</div>
            <div class="text item">登录：{{ loginTime }}</div>
        </el-card>
    </div>
</template>

<script lang="ts">

import { onMounted, onBeforeUnmount, ref } from 'vue';
import { useAuthStore } from '@/store/auth';

export default {
     name: 'UserCenter',
     components: {
     },
     setup() {
          
          const store = useAuthStore();
          const username = ref('');
          const loginTime = ref('');
          username.value = store.$state.userInfo?.username||'';
          loginTime.value = store.$state.userInfo?.logintime||'';
          onMounted(() => {
               //console.log("ChatMusic");
          });
          onBeforeUnmount(() => {

          });
          return {
            username,
            loginTime
          };
     }

}
</script>

<style scoped>
.bg-image {
    /* 引用背景图片 */
    background-image: url(~@/assets/bgimage/bk-girl-cat.jpg);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    /* 设置其他的样式规则 */
    width: 100%;
    height: 100%;
}

.text {
    font-size: 14px;
}

.item {
    padding: 18px 0;
}

.box-card {
    width: 80%;
    max-width: 380px;
    background-color: rgb(185 187 217 / 81%);
    position: fixed;
    top: 120px;
    left: 70px;
    z-index: 1000;
    display: flex;
  justify-content: space-between;
    /* 确保置于顶层 */
}
</style>