import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-589fd3b0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "bg-image" }
const _hoisted_2 = { class: "text item" }
const _hoisted_3 = { class: "text item" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_card = _resolveComponent("el-card")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_card, {
      class: "box-card",
      shadow: "hover"
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, "欢迎：" + _toDisplayString($setup.username), 1),
        _createElementVNode("div", _hoisted_3, "登录：" + _toDisplayString($setup.loginTime), 1)
      ]),
      _: 1
    })
  ]))
}