import axios from 'axios'
import { ElMessage } from 'element-plus'

//创建axios实例
const Service = axios.create({
    // 设置baseURL地址
    //baseURL: 'http://124.223.195.137:5000',
    //定义统一的请求头
    headers: {
        'Content-Type': "	application/json;charset=UTF-8"
    },
    //配置请求超时时间
    time: 10000
})

//请求拦截器
Service.interceptors.request.use((config) => {
    if (window.localStorage.getItem('token')) {
        //配置请求头
        config.headers.Authorization = 'Bearer ' + window.localStorage.getItem('token')
    }
    return config
})

//响应拦截器
Service.interceptors.response.use((response) => {
        //获取接口返回结果
        console.log('响应拦截器', response)
        const res = response.data
        if (res.code === "0") {
            return res
        }
        else if (res.code === "1") {
            ElMessage.error((res.message || '异常') + (res.data || ''));
        }
        else {
            return res
        }
    },
    error => {
        // 根据状态码进行错误处理
        const { response } = error;
        if (response) {
            const { status } = response;
            const { data: { message } } = response;
            if (status === 401) {
                // 处理 401 异常逻辑
                ElMessage.error(message?message:'401:未授权！');
            } else if (status === 403) {
                // 处理 403 异常逻辑
                ElMessage.error(message?message:'403:权限不足！');
            }
        }
        return Promise.reject(error)
    })

export default (Service)